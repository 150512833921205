@media (max-width:991px)
{
    /* .main-admin.active .left-admin {
        width: 0;
        opacity: 0;
        visibility: hidden;
    } */
    .main-admin.active .right-admin {
        width: 100%;
    }
    .toggle-btn {
        opacity: 1;
        visibility: visible;
    }
    .right-admin {
        width: 100%;
    }
    .left-admin {
        float: left;
        position: absolute;
        height: 100vh;
        background: #f8f8f8;
        left: 0;
        top: 0;
        width: 31%;
        opacity: 0;
    visibility: hidden;
        z-index: 9;
    }
    .data-table {
        padding: 16px 16px;
        margin: 10px 0;
    }
    .data-chart {
        padding: 20px 20px;
        margin: 10px 0;
    }
    .inner-scroll .row .col-md-7 {
        width: 100%;
    }
    .inner-scroll .row .col-md-5 {
        width: 100%;
    }
    .main-admin.active .left-admin {
        opacity: 1;
        visibility: visible;
    }
}
@media (max-width:767px)
{
    .inner-scroll {
        height: auto;
        overflow-y: auto;
    }
    .top-bar-left .form-group input {
        min-width: auto;
        width: 91%;
        margin-left: auto;
        float: right;
    }
    .right-admin {
        padding: 0 10px;
    }
    .top-bar {
        padding: 8px 14px;
    }
    .top-bar-left span {
        left: 50px;
    }
    .toggle-btn {
        position: absolute;
        left: 22px;
        right: inherit;
        top: 17px;
        padding: 3px 8px;
    }
    .top-bar-right {
        margin-top: 12px;
    }
    .inner-box-shd {
        margin: 20px 0 0;
    }
    .inner-box-shd h4 {
        font-size: 23px;
    }
    .map-iner {
        margin: 17px 0 10px;
    }
    .data-table table {
        width: 610px;
    }
    .data-table {
        overflow-x: auto;
    }
    .outer-table table {
        width: 640px;
    }
    .outer-table table {
        width: 640px;
    }
    .filter-button {
        padding: 0;
    }
    .filter-bn button {
        padding: 9px 17px;
    }
    .left-admin {
        position: fixed;
        height: 100vh;
        width: 69%;
    }
    .logo {
        padding: 17px 0 31px;
    }
    .inner-form {
        padding: 20px 4px;
    }
    .inner-blog-add {
        padding: 13px;
        margin: 7px 0;
    }
    .inner-scroll.firld-from .form-group {
        margin-bottom: 15px;
    }
}