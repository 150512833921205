body {
  margin: 0 auto;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background: #f8f8f8;
}

.admin-page {
  display: flex;
  flex-wrap: wrap;
  background: #f8f8f8;
}
.form-group.media-upload span img {
  width: 100%;
  max-width: 150px;
}
.left-admin {
  width: 15%;
  float: left;
  position: relative;
  height: 100vh;
}
.toggle-btn span {
  padding: 0 !important;
  margin: 0 !important;
}
.right-admin {
  width: 85%;
  float: left;
  padding-left: 25px;
}
.toggle-btn {
  /* position: absolute; */
  top: 30px;
  right: 0;
  background: #0f5cba;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 6px 11px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}
.toggle-btn i {
  color: #fff;
}
.logo a {
  color: #1060bb;
  text-decoration: none;
  font-size: 19px;
  font-weight: 600;
  margin: 0 auto;
  display: table;
}
.left-admin span {
  color: #ced1d6;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-left: 25px;
  margin-bottom: 16px;
  display: table;
  width: 100%;
}
.left-admin ul {
  padding: 0 25px;
  margin: 0;
}
.left-admin ul li {
  list-style: none;
  margin-bottom: 7px;
}
.left-admin ul li a {
  width: 100%;
  display: table;
  padding: 16px 18px;
  border-radius: 100px;
  color: #5c6e9a;
  text-decoration: none;
}
.left-admin ul li a i {
  margin-right: 9px;
}
.logo {
  padding: 17px 0 100px;
}

.left-admin ul li a.active i {
  color: #fff;
}
.left-admin ul li a.active {
  background: #0d5cba;
  color: #fff;
}
.logout {
  padding: 0 25px;
  position: absolute;
  bottom: 20px;
}
.logout a {
  width: 100%;
  display: table;
  padding: 16px 18px;
  border-radius: 100px;
  color: #5c6e9a;
  text-decoration: none;
}
.outer-right-admin .row {
  margin: 0;
}
.inner-scroll.firld-from .form-group textarea {
  border-radius: 6px;
  border: 1px solid #dee2e6;
  background: #fff;
  height: auto;
  width: 100%;
  padding: 0 12px;
  color: #9499a1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.logout a i {
  margin-right: 9px;
}
.top-bar {
  background: #fff;
  display: flex;
  padding: 15px 25px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.top-bar .row {
  width: 100%;
}
.top-bar-left .form-group {
  position: relative;
}
.inner-scroll.firld-from .form-group input[type="file"] {
  position: absolute;
  left: auto;
  top: 30px;
  width: 100%;
  float: none;
  display: table;
  height: 110px;
  border: 0;
  font-size: 0;
  padding: 30px;
}
span.image-pre {
  border: 2px dashed #cacaca;
  position: relative;
  width: 100%;
  display: flex;
  border-radius: 10px;
  height: 110px;
  text-align: center;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  flex-direction: column;
  background: #fff;
}
.image-preview img {
  max-width: 130px;
  border-radius: 10px;
  border: 1px dashed #c6c6c6;
  padding: 4px;
  margin-right: 10px;
}
span.image-pre p {
  margin: 0;
  color: #a5a5a5;
}
span.image-pre i {
  font-size: 24px;
  margin-bottom: 7px;
  color: #909090;
}
.image-preview {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.top-bar-left .form-group input {
  border-radius: 20px;
  background: #f8f8f8;
  border: 0;
  height: 45px;
  padding: 0 15px 0 55px;
  min-width: 450px;
}
.inner-imag-wrap {
  position: relative;
}
.inner-imag-wrap span {
  position: absolute;
  right: 6px;
  top: 0;
  background: #1260bb;
  width: 19px;
  height: 19px;
  color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}
.top-bar-left span {
  position: absolute;
  left: 22px;
  top: 8px;
}
.top-bar-right {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
}
.top-bar-right h5 {
  color: #5f677d;
  font-size: 15px;
  margin: 0;
}
.top-bar-right p {
  margin-bottom: 0;
  font-size: 12px;
  color: #888a91;
}
.top-bar-right span {
  margin-left: 17px;
}
.top-bar-right span img {
  max-width: 44px;
  border-radius: 100px;
}
.inner-box-shd {
  background: #fff;
  border-radius: 10px;
  padding: 23px 20px;
  margin: 29px 0;
  text-align: center;
}
.inner-box-shd img {
  margin-bottom: 13px;
}
.inner-box-shd h4 {
  color: #2f3f67;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  margin: 0;
}
.inner-box-shd span {
  color: #5c6e9a;
  font-size: 14px;
  margin: 0;
}
.map-iner img {
  width: 100%;
}
.map-iner {
  margin-bottom: 32px;
}
.data-table {
  background: #fff;
  border-radius: 10px;
  padding: 33px 30px;
  margin: 29px 0;
  text-align: center;
}
.data-table table {
  width: 100%;
}
.data-table table th {
  text-align: left;
  color: #5c6e9a;
  font-size: 15px;
  padding: 15px 13px;
  font-weight: 500;
}
.data-table td {
  text-align: left;
  font-size: 14px;
  padding: 10px 13px;
  color: #5c6e9a78;
  border-bottom: 1px solid #f0f0f0;
}
.data-table td span img {
  max-width: 43px;
  margin-right: 11px;
  border-radius: 100px;
}
.data-table td span {
  position: absolute;
  left: 13px;
  top: 9px;
}
.data-table tr td:first-child {
  width: 290px;
  padding-left: 70px;
  position: relative;
}
.data-table tr:last-child td {
  border: 0;
}
.inner-scroll {
  height: calc(100vh - 75px);
  overflow-y: auto;
}
.data-chart {
  background: #fff;
  border-radius: 10px;
  padding: 33px 30px;
  margin: 29px 0;
  text-align: center;
}
.data-chart h5 {
  color: #2f3f67;
  font-size: 15px;
  margin-bottom: 21px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.title h4 {
  color: #3d475c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.title {
  padding: 17px 0;
}
.title ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.title ul li {
  list-style: none;
  margin-right: 27px;
  position: relative;
}
.title ul li::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 9px;
  width: 8px;
  height: 8px;
  border: 2px solid #9499a1;
  border-top: 0;
  border-left: 0;
  transform: rotate(-45deg);
}
.title ul li:first-child::before {
  opacity: 0;
}
.title ul li a {
  color: #9499a1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}
.inner-form {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 48px 0px rgba(0, 0, 0, 0.04);
  padding: 30px;
}
.inner-scroll.firld-from .form-group label {
  color: #3d475c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  margin-bottom: 5px;
}

.inner-blog-add h5 {
  padding: 0 0px 11px;
  font-size: 17px;
}
.inner-scroll.firld-from .form-group label.switch {
  width: auto;
  width: 50px;
  clear: both;
  display: flex;
  align-items: center;
  padding-left: 60px;
  margin-left: 0px;
}
.inner-form h6 {
  padding: 0 12px 11px;
  font-size: 17px;
}
.blog-add .row {
  margin: 0 0px 0 -15px;
}
.form-group.swith-btn {
  display: flex;
  align-items: center;
}
.inner-blog-add {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 48px 0px rgba(0, 0, 0, 0.04);
  padding: 30px;
  margin: 27px 0;
}
.form-group.media-upload span {
  border-radius: 8px;
  border: 1px dashed #dee2e6;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 335px;
  padding: 30px 0;
  justify-content: center;
  text-align: center;
  pointer-events: none;
  z-index: 9;
  position: relative;
}
.save-btn button {
  border-radius: 6px;
  background: #e9ecef;
  border: 0;
  color: #6c757d;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  padding: 12px 30px;
}
.save-btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 30px;
}
.save-btn button.save {
  background: #1060bb;
  color: #fff;
  margin-left: 10px;
}
.form-group.media-upload {
  position: relative;
}
.inner-scroll.firld-from .form-group.media-upload input {
  position: absolute;
  top: 30px;
  width: 100%;
  height: 100%;
  border: 0;
  font-size: 0;
  padding: 50px 0;
}
.form-group.media-upload p {
  color: #9499a1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 17px;
}
.form-group.media-upload h3 {
  color: #1060bb;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.form-group.media-upload label {
  color: #9499a1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.form-group.media-upload h6 {
  color: #9499a1;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 14px 0 8px;
}
.form-group.swith-btn p {
  margin: 0 0 0 14px;
  color: #9499a1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.inner-scroll.firld-from .form-group input,
.inner-scroll.firld-from .form-group select {
  border-radius: 6px;
  border: 1px solid #dee2e6;
  background: #fff;
  height: 40px;
  width: 100%;
  padding: 0 12px;
  color: #9499a1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.inner-scroll.firld-from .form-group {
  margin-bottom: 23px;
  position: relative;
}
.chk {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chk input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.chk:hover input ~ .checkmark {
  background-color: #dee2e6;
}

/* When the checkbox is checked, add a blue background */
.chk input:checked ~ .checkmark {
  background-color: #1060bb;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.blog-table table tr td {
  color: #9499a1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 18px 25px;
  border-bottom: 1px dashed #ddd;
}
.blog-table table tr td span.pub {
  color: #11b981;
  border-radius: 16px;
  background: #cff1e6;
  padding: 6px 13px;
}
.add-pagination {
  padding: 36px 25px;
  display: flex;
  justify-content: space-between;
}
.add-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
}
.add-pagination ul li {
  list-style: none;
  margin-left: 10px;
}
.add-pagination ul li.active a {
  background: #1060bb;
  color: #fff;
}
.add-pagination p span {
  color: #9499a1;
}
.add-pagination p {
  font-size: 14px;
  margin: 0;
  color: #3d475c;
}
.add-pagination ul li a {
  text-decoration: none;
  padding: 6px 11px;
  border-radius: 4px;
  min-width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9499a1;
}
.blog-table table tr td.clr-black {
  color: #3d475c;
}
.blog-table table tr td span.pub.arc {
  color: #0ea5e9;
  background: #cfedfb;
}
.blog-table table tr td span.pub.unbu {
  color: #e11d48;
  background: #f9d2da;
}
.blog-table table tr td select {
  border-radius: 6px;
  background: #e9ecef;
  border: 0;
  color: #6c757d;
  font-size: 14px;
  padding: 10px 14px;
}
/* Show the checkmark when checked */
.chk input:checked ~ .checkmark:after {
  display: block;
}
/* .blog-table table tr th:first-child {
		width: 10px;
		padding-right: 0;
	} */
.blog-table table tr td img {
  max-width: 42px;
  border-radius: 100px;
  max-height: 42px;
  object-fit: cover;
  margin-right: 10px;
}
/* Style the checkmark/indicator */
.chk .checkmark::after {
  left: 10px;
  top: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.filter-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 0;
}
.filter-bn button {
  border-radius: 6px;
  background: #1060bb;
  padding: 13px 23px;
  border: 0;
  color: #fff;
}
.blog-table {
  background: #fff;
  border-radius: 19px;
  width: 98%;
  padding: 35px 0;
}
.blog-table table tr th {
  color: #9499a1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 18px 25px;
}
.blog-table table {
  width: 100%;
}
.right-filter button {
  border: 1px solid #6c757d;
  background: transparent;
  color: #6c757d;
  font-size: 14px;
  padding: 9px 13px;
  border-radius: 5px;
  margin-left: 6px;
}
.right-filter button.red {
  border-color: #e11d48;
  color: #e11d48;
}
.blog-table-filetr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 9px;
  border-bottom: 1px solid #ddd;
}
.right-filter span {
  color: #9499a1;
  font-size: 15px;
}
.right-filter {
  display: flex;
  align-items: center;
}
.filter-bn a {
  background: #fff;
  box-shadow: 0 0 10px 1px #dddddd45;
  padding: 13px 23px;
  color: #9499a1;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 9px;
}

.logo-img {
  max-width: 120px;
  /* width: 115px; */
  /* transform: translateX(-52px); */
}

.login_form_outer {
  max-width: 430px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
.login_form {
  background: #f7f7f7;;
  border-radius: 5px;
  padding: 30px;
}
.login_form img{
	width: 50%;
	height: 100px;
}
.login_form h3 {
  border-top: 1px solid #e5e5e5;
  color: #ca8416;
  font-weight: 400;
  margin: 0;
  font-size: 25px;
  padding-top: 20px;
}
.login_form h2 {
  border-top: 1px solid #e5e5e5;
  color: #1194c8;
  font-weight: 400;
  margin: 0;
  padding-top: 20px;
}

.login_form label {
  font-size: 18px;
  color: #b5bbcb;
  font-weight: 400;
}

.login_form .form-control {
  box-shadow: none;
  height: auto;
  padding: 8px 15px;
}
.login_form h2 + p {
  margin-top: 20px;
}
.login_form_outer .blue_btn {
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 30px;
}
.inner_chnage_password .login_form_outer {
  max-width: 430px;
  position: unset;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: unset;
  width: unset;
}
@media screen and (max-width: 767px) {
  .login_form_outer {
    margin: 20px auto 0;
  }
}
.logo_row {
  text-align: center;
  width: 100%;
  display: block;
  margin: -4px 1px 27px 0;
}
login_form .form-group {
  margin-top: 20px;
  margin-bottom: 0;
}

.blue_btn {
  background: #1194c8;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 7px 30px;
  display: inline-block;
  line-height: 26px;
  margin-top: 20px;
  font-weight: 700;
  margin-left: 10px;
}

.blue_btn:hover {
  background: #1194c8;
}
.blue_btn {
  cursor: pointer;
}
